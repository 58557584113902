import * as React from "react";
import {
  Modal,
  getTheme,
  mergeStyleSets,
  FontWeights,
  Stack,
} from "@fluentui/react";
import { DefaultButton, IconButton } from "@fluentui/react/lib/Button";
import { TextField } from "@fluentui/react/lib/TextField";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { thumbsUpReactionLambda } from "../../services/awsService";
import { useCallback, useEffect } from "react";

const theme = getTheme();
const cancelIcon = { iconName: "Cancel" };

const classNames = mergeStyleSets({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
    width: '400px'
  },
  header: [
    theme.fonts.xLarge,
    {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "12px 24px",
      borderBottom: `1px solid ${theme.palette.neutralLight}`,
      fontWeight: FontWeights.semibold,
    },
  ],
  body: {
    padding: "1rem",
  },
  formField: {
    marginBottom: "1rem",
  },
  pickerStyle: {
    width: "180px",
  },
  dataSpace: {
    paddingLeft: "0.5rem",
  },
});

interface FeedbackModalProps {
  feedbackInfo: any;
  isOpen: boolean;
  onClose: (callSuccess: string) => void;
}

function debounce<T extends (...args: any[]) => void>(
  func: T,
  delay: number
): T {
  let timeoutId: NodeJS.Timeout;

  return ((...args: Parameters<T>) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  }) as T;
}

const FeedbackModal: React.FunctionComponent<FeedbackModalProps> = ({
  feedbackInfo,
  isOpen,
  onClose,
}) => {
  const [feedback, setFeedback] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  

  const [errors, setErrors] = React.useState({
    feedback: ""
  });
  
  const validate = () => {
    const newErrors = {
      feedback: ""
    };
    if (!feedback) newErrors.feedback = "Feedback is required.";
    if (feedback.length < 100) newErrors.feedback = "Feedback text field should be minimum 100 characters.";

    setErrors(newErrors);
    return (
      !newErrors.feedback 
    );
  };

  const onFeedbackChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (newValue) {
      setFeedback(newValue || "")
    }
  };

  const debouncedInputChange = debounce(onFeedbackChange, 500);

  const onSubmit = async () => {
    if (!validate()) return;

    setIsLoading(true);

    let feedbackParam = {
        "feedback_code": "20",
        "interaction_id": feedbackInfo?.interactionId,
        "sessionID": feedbackInfo?.sessionID,
        "feedback_text": feedback
      }
      thumbsUpReactionLambda(feedbackParam, (response: any) => {
        
        setIsLoading(false);
        if(response?.errorMsg !== undefined) {
            
            onClose('error');
        } else { 
            onClose('success');
            clearFormFields(); // to clear the input field after the successful submit
        }
      });
  };  

  const onDismiss = () => {
    clearFormFields();
    onClose('error');
  };

  const clearFormFields = () => {
    setFeedback("");
  };

  useEffect(() => {
    if (feedback)
      if (!validate()) return;
    
  }, [feedback]);

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      isBlocking={false}
      containerClassName={classNames.modal}
    >
      <div className={classNames.header}>
        <span>Feedback</span>
      </div>
      <div className={classNames.body}>
        <TextField
          label="Enter Feedback"
          placeholder="Enter Feedback"
          value={feedback}
          multiline
          rows={5}
          resizable={false}
          onChange={onFeedbackChange}
          onKeyUp={debouncedInputChange}
          errorMessage={errors.feedback}
        />
        <Stack
          horizontal
          className="buttonCont"
          verticalAlign="space-between"
        >
          <PrimaryButton
            text="Submit"
            className="buttonSpace modal-submit-btn"
            onClick={onSubmit}
          />
        </Stack>
      </div>        
      <div
        className={`loading ${isLoading ? 'show' : 'hide'}`}
      ></div>
      <div
        className={`backdrop ${
          isLoading ? 'show' : 'hide'
        }`}
      ></div>
    </Modal>
  );
};

export default FeedbackModal;
